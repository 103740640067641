.player {
  background: #080c0a;
  position: fixed;
  top: 55px;
  left: 10px;
  z-index: 20;
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 16px;
}

.button {
  height: 20px;
  width: auto;
}

.ethDiamond {
  width: 4.4rem;
}

.howHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.scenarioImg {
  height: 150px;
  width: auto;
}

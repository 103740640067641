@use "sass:list";

body {
  background-image: radial-gradient(circle at 50% 50%, #112620 0%, #020605 55%);
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;

  @media (max-width: 600px) {
    font-size: 8.5px;
  }
}

$colors: #e5d8fa, #aa8ed7, #45bf75, #6b40fb;

:root {
  @for $i from 1 through length($colors) {
    --color-#{$i}: #{list.nth($colors, $i)};
  }
}

@for $i from 1 through length($colors) {
  .color-#{$i} {
    color: var(--color-#{$i});
  }
}

body {
  margin: 0;
  font-family: VT323;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Type
.type-0 {
  font-size: 1.8rem;
}

.type-1 {
  font-size: 2.4rem;
}

.type-2 {
  font-size: 3.5rem;
}

// layout
.spacer > *:not(:last-child) {
  margin-bottom: 2rem;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

.center {
  text-align: center;
}

a,
a:visited {
  color: var(--color-1);
}

.tradeRow {
  display: grid;
  position: relative;
  padding: 0.5rem;
  border-bottom: 1px solid var(--color-3);
  grid-template-columns: repeat(3, 1fr);
  cursor: pointer;
  & > * {
    align-self: center;
  }
  & img,
  .loading {
    width: 60px;
  }
}

.lgImg {
  width: 100%;
  max-width: 500px;
}

.listWrap {
  border: 1px solid var(--color-3);
}

.sellRow {
  grid-template-columns: repeat(4, 1fr);
}

.tabWrap {
  display: flex;
  border: 1px solid var(--color-4);
  :global {
    p.active {
      background: var(--color-4);
      color: var(--color-1);
    }
  }

  & p {
    flex: 1;
    text-align: center;
    cursor: pointer;
  }
}

.modalPaper {
  background-color: #000000 !important;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
    & iframe {
        border: none;
        max-width: 100%;
        position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
    }
}

.frameWrap {
    width: 25%;
    position: relative;
    padding-bottom: 25%; 

}
.btn {
  border: 1px solid var(--color-3);
  font-family: inherit;
  color: var(--color-1);
  font-size: 2.4rem;
  background: none;
  padding: 10px 30px;

  &:disabled {
    opacity: 0.5;
  }
}
